import logo from './logo.svg';
import './App.css';
import {useEffect, useState} from "react";




function App() {


    const [password,setPassword] = useState("");

    useEffect(()=>{

        if(password === "") return
        fetch('/area51', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({password}),
        })
            .then(resp => {

                if(resp.status===401){
                    console.log("wrong")
                }else{
                    console.log("itworks");

                    window.location ="/"
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    },[password])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="logo" alt="logo" />
<p>
         <input type={"password"} placeholder={"Enter password"} className={"input"} value={password} onChange={(event)  => setPassword(event.target.value) }/>
</p>
      </header>
    </div>
  );
}

export default App;
